import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./login.scss";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isRightPanelActive, setIsRightPanelActive] = useState(false);
  const [load, setLoad] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      setLoad(true);
      const response = await axios.post("/api/auth/owner/login", credentials);

      if (response.status === 200) {
        const { details } = response.data;
        dispatch({ type: "LOGIN_SUCCESS", payload: details });
        navigate("/");
      } else {
        const errorMessage = "You are not allowed!";
        dispatch({ type: "LOGIN_FAILURE", payload: { message: errorMessage } });
      }
    } catch (error) {
      setLoad(false);
      const errorResponse = error.response.data;
      dispatch({ type: "LOGIN_FAILURE", payload: errorResponse });
    }
  };

  const togglePanel = () => {
    setIsRightPanelActive(!isRightPanelActive);
  };

  return (
    <div className="login-page">
      <div
        className={`container ${
          isRightPanelActive ? "right-panel-active" : ""
        } ${isMobile ? "mobile" : ""}`}
        id="container"
      >
        <div className="form-container sign-up-container">
          <form action="#">
            <h1>Create Account</h1>
            <input type="text" placeholder="Name" />
            <input type="email" placeholder="Email" />
            <input type="password" placeholder="Password" />
            <button disabled type="button">
              Sign Up
            </button>
          </form>
        </div>
        <div className="form-container sign-in-container">
          <form onSubmit={handleClick}>
            <h1>Sign in</h1>
            <span className="error">
              {error && <span>{error.message}</span>}
            </span>
            <input
              type="text"
              placeholder="Username"
              id="username"
              onChange={handleChange}
              className="lInput"
            />
            <input
              type="password"
              placeholder="Password"
              id="password"
              onChange={handleChange}
              className="lInput"
            />
            <button type="submit" disabled={load} className="lButton">
              {load ? "Please Wait..." : "Login"}
            </button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome Back!</h1>
              <p>
                To keep connected with us please login with your personal info
              </p>
              <button className="ghost" onClick={togglePanel}>
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1 className="titlee">
                SHOWERS HOSTEL <br />- ADMIN -
              </h1>
              <p>Enter your personal details and to view hostel statistics</p>
              <button className="ghost" onClick={togglePanel}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
